import React, { useState } from 'react';
import logo from '../../assets/Logo_dga.png';
import logoSA from '../../assets/Patch_SA.png';
import logoiftl from '../../assets/patch_IFTL_vf.png';
import logo_filgoods from '../../assets/Questionnaire_GRA.png';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed

  return (
    <nav style={styles.navbar} className="navbar bg-light">
        <div className="container-fluid">
          <img src={logo} alt="Logo" width={isMobile ? 50 : 100} height={isMobile ? 50 : 100} className="d-inline-block align-text-top" />
        </div>
        <div>
          <img
            src={logo_filgoods}
            style={styles.GRAtitle}
            alt="Logo"
            width={isMobile ? 150 : 300}
            height={isMobile ? 150 : 300}
            className="d-inline-block align-text-top"
          />
        </div>
        <div style={styles.menubar}>
          <img src={logoiftl} alt="Logo" width={isMobile ? 50 : 100} height={isMobile ? 50 : 100} className="d-inline-block align-text-top" />
          <img src={logoSA} alt="Logo" width={isMobile ? 50 : 100} height={isMobile ? 50 : 100} className="d-inline-block align-text-top" />
        </div>
    </nav>
  );
}

const styles = {
  GRAtitle: {
    marginTop: '-30%',
    marginLeft: '20%',
  },
  navbar: {
    backgroundColor: '#282c34',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menubar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    color: 'white',
  },
};

export default Navbar;
