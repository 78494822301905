import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import size from "../data/QuestionAndAnswer.json";


const xMax =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

let Charge_travail = 0;
let forme_general = 0;
let Organisation_vol = 0;
let Expérience_globale = 0;
let Expérience_essai = 0;
let Expérience_aronef = 0
let progress_Soucis_perso = 0;
let Constitution_équipe = 0;
let progress_Pression_ressentie = 0;
let Equipements_particuliers = 0;
let Conditions_Meteo = 0;
let Espaces_aeriens = 0;
let Contraintes_platesformes = 0;

function ProgressCircu({ QuestionAndAnswer, Count }) {
    const [type1, setType1] = useState(0);
    const [type2, setType2] = useState(0);
    const [type3, setType3] = useState(0);
    let pilote = 1

    let testa = Count;
    if (localStorage.getItem("selectedSpecialite") == "Pilote") {
        pilote  =  2
        console.log("pilote dans progress circu")
    }
    else {
        console.log("NON pilote dans progress circu")

    }

    let Answer = localStorage.getItem(QuestionAndAnswer.Formulaire[testa > 0 ? (testa == 8 ? testa - pilote : testa == 7 ? testa - 2 : testa - 1) : testa].Question);
    if (testa < (QuestionAndAnswer.Formulaire.length) && QuestionAndAnswer.Formulaire[testa].Question) {
        console.log(testa, "testa");
        console.log(QuestionAndAnswer.Formulaire[testa > 0 ? (testa == 8 ? testa - pilote : testa == 7 ? testa - 2 : testa - 1) : testa].Question);

        switch (QuestionAndAnswer.Formulaire[testa > 0 ? (testa == 8 ? testa - pilote : testa == 7 ? testa - 2 : testa - 1) : testa].Question) {
            case "Charge de travail":
                if (testa === 1) {
                    Charge_travail = Math.round((Answer / (size.Formulaire[testa].Answer.length)) * 100);
                    Charge_travail = Charge_travail === 0 ? 1 : Charge_travail;
                    console.log("Charge_travail", Math.round((Answer / (size.Formulaire[testa].Answer.length)) * 100));
                    console.log(Answer + "/ " + (size.Formulaire[testa].Answer.length - 1));
                }
                break;
            case "Etat de forme général":
                forme_general = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                forme_general = forme_general === 0 ? 1 : forme_general;
                console.log(Answer + "/ " + size.Formulaire[testa].Answer.length);
                break;
            case "Impact de vos soucis personnels sur le vol":
                progress_Soucis_perso = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                progress_Soucis_perso = progress_Soucis_perso === 0 ? 1 : progress_Soucis_perso;
                console.log(progress_Soucis_perso, "progress_Soucis_perso");
                break;
            case "Organisation de la journée de vol":
                Organisation_vol = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                Organisation_vol = Organisation_vol === 0 ? 1 : Organisation_vol;
                console.log(Organisation_vol, "Organisation_vol");
                break;
            case "Pression extérieure ressentie":
                progress_Pression_ressentie = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                progress_Pression_ressentie = progress_Pression_ressentie === 0 ? 1 : progress_Pression_ressentie;
                console.log(progress_Pression_ressentie, "progress_Pression_ressentie");
                break;
            case "Expérience globale sur l'appareil":
                Expérience_globale = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                Expérience_globale = Expérience_globale === 0 ? 1 : Expérience_globale;
                console.log(Answer + "/ " + (size.Formulaire[testa].Answer.length - 1));
                console.log(Expérience_globale, "Expérience_globale");
                break;
                
            case "Pour pilote : Expérience récente aux commandes de l'aéronef":
                Expérience_aronef = Math.round((Answer / size.Formulaire[testa].Answer.length) * 100);
                Expérience_aronef = Expérience_aronef === 0 ? 1 : Expérience_aronef;
                console.log(Answer + "/ " + size.Formulaire[testa].Answer.length, "Expérience_commande_aronef");
                console.log(Expérience_aronef, "Expérience_aronef");
                break;
            case "Pour non pilote : Expérience récente sur l'aéronef":
                Expérience_aronef = Math.round((Answer / (size.Formulaire[testa].Answer.length )) * 100);
                Expérience_aronef = Expérience_aronef === 0 ? 1 : Expérience_aronef;
                console.log(Expérience_aronef, "Expérience_aronef");
                break;
            case "Expérience de la mission":
                Expérience_essai = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                Expérience_essai = Expérience_essai === 0 ? 1 : Expérience_essai;
                console.log(Expérience_essai, "Expérience_essai");
                break;
            case "Constitution de l'équipage.":
                Constitution_équipe = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                Constitution_équipe = Constitution_équipe === 0 ? 1 : Constitution_équipe;
                console.log(Constitution_équipe, "Constitution_équipe");
                break;
            case "Equipement particulier créant un inconfort":
                Equipements_particuliers = Math.round((Answer / (size.Formulaire[testa].Answer.length)) * 100);
                Equipements_particuliers = Equipements_particuliers === 0 ? 1 : Equipements_particuliers;
                console.log(Equipements_particuliers, "Equipements_particuliers");
                break;
            case "Conditions Météorologiques":
                Conditions_Meteo = Math.round(Answer / (size.Formulaire[testa].Answer.length - 2) * 100);
                Conditions_Meteo = Conditions_Meteo === 0 ? 1 : Conditions_Meteo;
                console.log(Conditions_Meteo, "<==== Conditions_Meteo", Answer, " ",size.Formulaire[testa].Answer.length - 1);
                console.log(Conditions_Meteo, "Conditions_Meteo");
                break;
            case "Espaces aériens":
                console.log(Answer, " ",size.Formulaire[testa].Answer.length);
                Espaces_aeriens = Math.round((Answer / (size.Formulaire[testa].Answer.length - 1)) * 100);
                Espaces_aeriens = Espaces_aeriens === 0 ? 1 : Espaces_aeriens;
                console.log(Espaces_aeriens, "<==== Espaces_aeriens");
                break;
            default:
                break;
        }

    } else if (testa === (QuestionAndAnswer.Formulaire.length)) {
        Contraintes_platesformes = Math.round((Answer / (size.Formulaire[testa - 1].Answer.length - 1)) * 100);
        Contraintes_platesformes = Contraintes_platesformes === 0 ? 1 : Contraintes_platesformes;
        console.log(Contraintes_platesformes, "Contraintes_platesformes");
    }
    useEffect(() => {
        setType1(parseFloat(localStorage.getItem("moyentype1")));
        console.log("icic onte ct", localStorage.getItem("moyentype2"));
        setType2(parseFloat(localStorage.getItem("moyentype2")));
        setType3(parseFloat(localStorage.getItem("moyentype3")));
    }, []); // Effect s'exécute une seule fois après le montage du composant
    return (
        <body>
            <div style={{ width: "100%" }}>
                <div style={styles.contain}>
                    <div style={styles.groupContainer}>
                    <text>{type1 > 66 ? 'État personel a risque !' : ''}</text>
                    <div style={{ ...styles.circularProgressBarContainer, border: type1 > 66 ? '4px solid red' : type1 > 33 ? '4px solid orange' : type1 || type1 === 0 ? '4px solid green' : '4px solid gray' }}>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Charge_travail !== 0 ? 100 : 0}
                                styles={Charge_travail >= 0 && Charge_travail < 33 ? styles.green : Charge_travail === 33 ? styles.yellow : Charge_travail === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>👨‍💻</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Charge travail
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={forme_general !== 0 ? 100 : 0}
                                styles={forme_general >= 0 && forme_general < 25 ? styles.green : forme_general === 33 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>😴</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Forme general
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>

                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={progress_Soucis_perso !== 0 ? 100 : 0}
                                styles={progress_Soucis_perso >= 0 && progress_Soucis_perso < 33 ? styles.green : progress_Soucis_perso === 33 ? styles.yellow : progress_Soucis_perso === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>🤯</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    soucis perso
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>

                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Organisation_vol !== 0 ? 100 : 0}
                                styles={
                                    Organisation_vol >= 0 && Organisation_vol < 33 ? styles.green : Organisation_vol >= 33 && Organisation_vol < 50 ? styles.yellow : Organisation_vol > 50 && Organisation_vol < 100 ? styles.orange : styles.red
                                }
                            >
                                <br />
                                <br />

                                <div style={{ fontSize: "100%", paddingBottom: 10}}>📊</div>
                                <br />
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Organisation vol 
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={progress_Pression_ressentie !== 0 ? 100 : 0}
                                styles={progress_Pression_ressentie >= 0 && progress_Pression_ressentie < 25 ? styles.green : progress_Pression_ressentie === 33 ? styles.yellow : progress_Pression_ressentie == 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>😰 </div>
                                <br />
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Pression ressentie
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>

                    </div>
                    <div style={styles.groupContainer}>
                    <text>{type2 >= 60 ? 'Manque d\'experience de l\'equipage !' : ''}</text>
                    <div style={{ ...styles.circularProgressBarContainer, border: type2 >= 60 ? '4px solid red' : type2 > 33 ? '4px solid orange' : type2 || type2 === 0 ? '4px solid green' : '4px solid gray' }}>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Expérience_globale !== 0 ? 100 : 0}
                                styles={Expérience_globale >= 0 && Expérience_globale < 25 ? styles.green : Expérience_globale === 33 ? styles.yellow : Expérience_globale === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%"}}>👴</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginBottom: -8 }}>
                                    Expérience globale
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Expérience_aronef !== 0 ? 100 : 0}
                                styles={Expérience_aronef >= 0 && Expérience_aronef < 25 ? styles.green : Expérience_aronef === 25 ? styles.yellow : Expérience_aronef == 50 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%"}}>🛫</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: 10 }}>
                                    Expérience aronef
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Expérience_essai !== 0 ? 100 : 0}
                                styles={Expérience_essai >= 0 && Expérience_essai < 33 ? styles.green : Expérience_essai === 33 ? styles.yellow : Expérience_essai == 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%"}}>🧪</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: 10 }}>
                                    Expérience essai
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                    </div>
                    <div style={styles.groupContainer}>
                    <text>{type3 > 54 ? '\nCondition de Vol Défavorable !' : ''}</text>
                    <div style={{ ...styles.circularProgressBarContainer, border: type3 > 54 ? '4px solid red' : type3 > 33 ? '4px solid orange' : type3 || type3 === 0 ? '4px solid green' : '4px solid gray' }}>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Constitution_équipe !== 0 ? 100 : 0}
                                styles={Constitution_équipe >= 0 && Constitution_équipe < 33 ? styles.green : Constitution_équipe === 33 ? styles.yellow : Constitution_équipe === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>🌐</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Constitution équipe
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Equipements_particuliers !== 0 ? 100 : 0}
                                styles={Equipements_particuliers >= 0 && Equipements_particuliers < 33 ? styles.green : Equipements_particuliers === 33 ? styles.yellow : Equipements_particuliers === 66  ? styles.orange :styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>⚙️</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Equipements particuliers
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Conditions_Meteo !== 0 ? 100 : 0}
                                styles={Conditions_Meteo >= 0 && Conditions_Meteo < 33 ? styles.green :  Conditions_Meteo === 50 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 10}}>🌤️</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                     Conditions Meteo
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Espaces_aeriens !== 0 ? 100 : 0}
                                styles={Espaces_aeriens >= 0 && Espaces_aeriens < 33 ? styles.green : Espaces_aeriens === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingBottom: 19 }}>🛩️</div>
                                <br></br>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: -5 }}>
                                    Espaces aeriens 
                                </div>
                                
                                
                            </CircularProgressbarWithChildren>
                        </div>
                        <div style={styles.spaceElement}>
                            <CircularProgressbarWithChildren
                                value={Contraintes_platesformes !== 0 ? 100 : 0}
                                styles={Contraintes_platesformes >= 0 && Contraintes_platesformes < 33 ? styles.green : Contraintes_platesformes === 33 ? styles.yellow : Contraintes_platesformes === 67 ? styles.orange : styles.red}
                            >
                                <br />
                                <br />
                                <div style={{ fontSize: "100%", paddingTop: 30 }}>🔒</div>
                                <div style={{ fontSize: "50%",fontWeight: 'bold', marginTop: 40}}>
                                    Contraintes platesformes 
                                </div>
                                <br></br>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </body>
    );
}
export default ProgressCircu;

const styles = {
    groupContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    red: {
        trail: {
            stroke: "#d6d6d6",
        },
        text: {
            fill: "#1e90ff",
            fontSize: "16px",
        },
        background: {
            fill: "#3e98c7",
            
        },
        path: {
            stroke: "red",
        },
    },
    yellow: {
        trail: {
            stroke: "#d6d6d6",
        },
        text: {
            fill: "#1e90ff",
            fontSize: "16px",
        },
        background: {
            fill: "#3e98c7",
        },
        path: {
            stroke: "yellow",
            
        },
    },
    blue: {
        path: {
            stroke: "#009BFF",
        },
    },
    orange: {
        trail: {
            stroke: "#d6d6d6",
        },
        text: {
            fill: "#1e90ff",
            fontSize: "16px",
        },
        path: {
            stroke: "orange",
        },
    },
    green: {
        trail: {
            stroke: "#d6d6d6",
        },
        text: {
            fill: "#1e90ff",
            fontSize: "16px",
        },
        background: {
            fill: "#3e98c7",
            '4px solid': 'red',
        },
        path: {
            stroke: "green",
        },
    },

    /*contain: {
        flex: 1,
        //tialle
        width: "124%",
        // marginLeft: xMax < 600 ? '20%' : 0,
        //metre tout les items en ligne
        display: "flex",
        //metre un espace entre les items
        justifyContent: "space-between",
        // flexDirection: xMax < 600 ? 'column' : 'row',
    },*/
    contain: {
        flex: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: xMax < 1200 ? 'column' : 'row',
    },
    
    circularProgressBarContainer: {
        // Ajoutez un bord autour du conteneur
        margin: "30px", // Ajoute un espacement extérieur autour du conteneur
        padding: "10px", // Ajoute un espacement intérieur autour du conteneur
        borderRadius: 30, // Arrondir les coins du conteneur
        display: "flex",
        justifyContent: "space-between",

    },
    spaceElement: {
        marginLeft: xMax < 1200 ? 5 : 20,
        marginRight: xMax < 1200 ? 5 : 20,
        width: '19%',
    },
};
