import { useState } from 'react';
import QuestionAndAnswer from '../data/QuestionAndAnswer.json';
import ButtonAnswer from './ButtonAnswer';
import ProgressCircu from './ProgressCircu';
import ProgressBar from './progress-bar/progress-bar';
import { useNavigate } from 'react-router';

function Question() {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed

  function nextQuestion() {
    localStorage.setItem(QuestionAndAnswer.Formulaire[5].Question, 99)
  }
  function handleSubmit(e) {
    e.preventDefault();

    if (count < (QuestionAndAnswer.Formulaire.length)) {
      console.log(localStorage.getItem("selectedSpecialite"));
      console.log("count", count);
      if (count === 5 && localStorage.getItem("selectedSpecialite") != "Pilote") {
        console.log("Selection question pour Non pilote")
        setCount(count + 2);
      }
      else if (count === 6 && localStorage.getItem("selectedSpecialite") === "Pilote") {
        console.log("Selection question pour pilote")
        setCount(count + 2);
      }
      else {
        setCount(count + 1);

      }

      localStorage.setItem("count", count == null ? 0 : count)
    }

  }
  function showresult() {
    navigate('/result');
  }
  function handleBack(e) {
    e.preventDefault();
    if (count === 8 & (localStorage.getItem("selectedSpecialite") === "Pilote")) {
      setCount(count - 2);
      return
    }
    else if (count === 7 & (localStorage.getItem("selectedSpecialite") !== "Pilote")) {
      setCount(count - 2);
      return
    }
    if (count > 0) {
      setCount(count - 1);
      localStorage.setItem("count", count)
    }
  }

  if (count < (QuestionAndAnswer.Formulaire.length)) {
    console.log("count", count, "taille", (QuestionAndAnswer.Formulaire.length - 1));
    return (
      <div className="App-header" >
        <div style={{ border: "2px solid White", padding: "10px" , borderRadius: "19px"}}>
          <text style={{ font: "bold", fontSize: "4vh" }}>
            {QuestionAndAnswer.Formulaire[count].Question}
          </text>
        </div>
        <text style={{fontSize: "2vh" }}>
          <br></br>
            {QuestionAndAnswer.Formulaire[count].description}
          </text>
        <ProgressBar key={1} completed={(count / ((QuestionAndAnswer.Formulaire.length - 1))) * 100} />
        <body className='App-body' style={styles.bodyQuestion}>
          <div>
            <br></br>
            <div style={styles.divcenter} >
              <form onSubmit={handleSubmit} >
                <ButtonAnswer QuestionAndAnswer={QuestionAndAnswer.Formulaire[count]}></ButtonAnswer>
                <button style={styles.button} onClick={handleBack} >Retour</button>
              </form>
            </div>
          </div>
          <div style={styles.textCount}>{count >= 7 ? count : count + 1}/{QuestionAndAnswer.Formulaire.length - 1}</div>

          {isMobile ? null : (
        <div style={styles.ProgressCircu}>
          <ProgressCircu QuestionAndAnswer={QuestionAndAnswer} Count={count}></ProgressCircu>
        </div>
      )}

        </body>
      </div>
    );
  }
  else {
    return (
      <div className="App-header" >
        <div style={styles.ratingmeter}>
          <button style={styles.buttonconfirm} onClick={showresult}>Voir resultat </button>
          <button style={styles.button} onClick={handleBack} >Retour</button>
        </div>
        {isMobile ? null : (

        <body className='App-body' style={styles.bodyQuestion}>
          <ProgressCircu QuestionAndAnswer={QuestionAndAnswer} Count={count}> </ProgressCircu>
        </body>
        )}
      </div>
    )
  }
}

export default Question;
const styles = {
  textCount: {
    position: 'absolute',
    right: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  ProgressCircu: {
    paddingTop: '10%',
    paddingBottom: '20%'
  },
  ratingmeter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  bodyQuestion: {
    shadowColor: "white",
    borderRadius: '10%',
  },
  buttonconfirm: {
    backgroundColor: '#1e90ff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px'
  },
  button: {
    backgroundColor: '#1e90ff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px'
  },
  divcenter: {
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  }

}