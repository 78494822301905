import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './View/MainPage';
import Questionview from './View/Questionview';
import ResultView from './View/ResultView';
import GrapheView from './View/GraphView';


function App() {
  return (
    <div className="page-container" style={styles.mainpage}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/Question" element={<Questionview />} />
        <Route path="/result" element={<ResultView />} />
        <Route path="/Graph" element={<GrapheView />} />
      </Routes>
    </div>
    
  );
  
}
const styles = {
  mainpage: {
    backgroundColor: '#282c34',

  },
};
export default App;