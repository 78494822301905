import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';

const options = {
  responsables: ['PN CZX', 'EPNER', 'PN IST'],
  specialites: ['Pilote', 'ENE/INE', 'MNE', 'PAER', 'Autre'],
  typesVol: ['Essai', 'Réception', 'Instruction', 'Plastron', 'Convoyage, liaison', 'Entrainement'],
};

function MainPage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedResponsable, setSelectedResponsable] = useState('');
  const [selectedSpecialite, setSelectedSpecialite] = useState('');
  const [selectedTypeVol, setSelectedTypeVol] = useState('');

  const [errors, setErrors] = useState({
    responsable: false,
    specialite: false,
    typeVol: false,
  });

  const navigate = useNavigate();

  function handleConfirmClick() {
    if (selectedResponsable !== '' && selectedSpecialite !== '' && selectedTypeVol !== '') {
      console.log('responsable', selectedResponsable);
      localStorage.clear();
      localStorage.setItem('selectedResponsable', selectedResponsable);
      localStorage.setItem('selectedSpecialite', selectedSpecialite);
      localStorage.setItem('selectedTypeVol', selectedTypeVol);
      navigate('/Question');
    } else {
      setErrors({
        responsable: selectedResponsable === '',
        specialite: selectedSpecialite === '',
        typeVol: selectedTypeVol === '',
      });
    }
  }

  return (
    <div>
      <Navbar />
      <div style={{
        backgroundColor: '#282c34',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: !isMobile ? '100vh' : '100%',
        marginTop: '-10%',
        color: 'white',
        justifyContent: 'center',
      }}>
        <div style={{ marginBottom: '2%' }}>
          {/* Vous pouvez ajouter un titre ou une description ici */}
        </div>
        <form style={isMobile ? { paddingTop: '30%' } : { display: 'flex' }}>
          <div style={styles.selectContainer}>
            <p>Responsable de l'activité :</p>
            {options.responsables.map((responsable) => (
              <label key={responsable} style={styles.label}>
                <input
                  type="radio"
                  value={responsable}
                  checked={selectedResponsable === responsable}
                  onChange={() => setSelectedResponsable(responsable)}
                />{' '}
                {responsable}
              </label>
            ))}
          </div>

          <div style={styles.selectContainer}>
            <p>Spécialité :</p>
            {options.specialites.map((specialite) => (
              <label key={specialite} style={styles.label}>
                <input
                  type="radio"
                  value={specialite}
                  checked={selectedSpecialite === specialite}
                  onChange={() => setSelectedSpecialite(specialite)}
                />{' '}
                {specialite}
              </label>
            ))}
          </div>

          <div style={styles.selectContainer}>
            <p>Type de vol :</p>
            {options.typesVol.map((typeVol) => (
              <label key={typeVol} style={styles.label}>
                <input
                  type="radio"
                  value={typeVol}
                  checked={selectedTypeVol === typeVol}
                  onChange={() => setSelectedTypeVol(typeVol)}
                />{' '}
                {typeVol}
              </label>
            ))}
          </div>
        </form>
        <button
          style={styles.buttonconfirm}
          type="button"
          onClick={handleConfirmClick}
        >
          Continuer !
        </button>
        <div style={{ color: 'red' }}>
          {errors.responsable && <p>Veuillez sélectionner un responsable.</p>}
          {errors.specialite && <p>Veuillez sélectionner une spécialité.</p>}
          {errors.typeVol && <p>Veuillez sélectionner un type de vol.</p>}
        </div>
      </div>
    </div>
  );
}

const styles = {
  selectContainer: {
    border: '2px solid #ddd',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'left',
  },
  label: {
    fontSize: '20px',
    display: 'block',
    backgroundColor: '#1e90ff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
  },
  buttonconfirm: {
    backgroundColor: '#1e90ff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '3%',
  },
};

export default MainPage;
