import QuestionAndAnswer from '../data/QuestionAndAnswer.json';
import ProgressCircu from '../components/ProgressCircu';
import RatingMeter from '../components/RatingMeter/rating_meter';
import React, { useState } from 'react';
import Navbar from '../components/Navbar/Navbar';

function Question() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div>
      <Navbar />
      <div className="App-header" >
        <div style={styles.ratingmeter}>
          <RatingMeter></RatingMeter>
        </div>
        <body className='App-body' style={styles.bodyQuestion}>
        {isMobile ? null : (
          <ProgressCircu QuestionAndAnswer={QuestionAndAnswer} Count={localStorage.getItem("count")}></ProgressCircu>
        )}
        </body>
      </div>
    </div>
  )
}

export default Question;
const styles = {

  ratingmeter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  bodyQuestion: {
    shadowColor: "white",
    borderRadius: '10%',
    width: '100%',
    height: '-100%',
    padding: '10%,'
  },

}