import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from 'react-chartjs-2';
import './GraphChart.js'
import QuestionAndAnswer from '../../../data/QuestionAndAnswer.json'

const regex = /-?\d+\.?\d*/g;

export default function GraphComponents({ city }) {
    const [data, setData] = useState([]);
    const [monthlyAverages, setMonthlyAverages] = useState([]);
    const [monthlyAveragesByElement, setMonthlyAveragesByElement] = useState(
        new Array(12).fill(null).map(() => new Array(6).fill(0))
    );
    const fetchData = async () => {
        const response = await axios.get("https://www.iftl-ev.fr/GRAcity/" + city);
        setData(response.data);
    };
    useEffect(() => {
        fetchData();
    }, [city]);

    useEffect(() => {
        const calculateMonthlyAverages = () => {
            const averages = new Array(12).fill(null);
            const averagesByelement = new Array(12).fill(null).map(() => new Array(6).fill(0));
            if (!data) {
                fetchData();
            }
            console.log(data, "data")
            data.forEach((item) => {
                const date = new Date(item.updatedAt);
                const month = date.getMonth();
                const stats = JSON.parse(item.stats);
                const average = stats.reduce((acc, val) => acc + val, 0) / stats.length;

                if (averages[month] === null) {
                    averages[month] = [average * 100];
                } else {
                    averages[month].push(average * 100);
                }
            });
            data.forEach((item) => {

                const date = new Date(item.updatedAt);
                const month = date.getMonth();

                const ArrayAverage = item.stats.match(regex).map(Number);

                for (let i = 0; i < ArrayAverage.length; i++) {
                    averagesByelement[month][i] += ArrayAverage[i];

                }
            });
            for (let month = 0; month < 12; month++) {
                for (let i = 0; i < 6; i++) {
                    if (averagesByelement[month][i]) {
                        console.log(month, "month", averages[month].length, "length")
                        averagesByelement[month][i] = (averagesByelement[month][i] / averages[month].length) * 100;
                        if (averagesByelement[month][i] > 100) {
                            console.log(averagesByelement[month][i], "averagesByelement[month][i]")
                        }
                    }
                }
            }
            const monthlyAverages = averages.map((average) => {
                if (average === null) {
                    return null;
                } else {
                    return average.reduce((acc, val) => acc + val, 0) / average.length;
                }
            });
            setMonthlyAverages(monthlyAverages);
            setMonthlyAveragesByElement(averagesByelement);

        };
        calculateMonthlyAverages();
    }, [data]);

    const chartData = {
        labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Moyen d'état general santé des contrôleurs aérien en pourcentage",
                data: monthlyAverages,
                backgroundColor: "rgba(75,192,192,1)",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top-left",                
            },
            title: {
                display: true,
                text: city,
            },
        },
    };
    const numCharts = monthlyAverages.length;
    const numRows = Math.ceil(numCharts / 2);
    const numCols = 2;
    const styles = {
        contain: {
            display: "grid",
            gridTemplateColumns: `repeat(${numCols}, 1fr)`,
            gridTemplateRows: `repeat(${numRows}, 1fr)`,
            gridGap: "20px",
        },
        graphMain: {
            gridColumn: "1 / 3",
            gridRow: "1 / 3",
        },
    }
    console.log(data, "data")
    return (
        <div style={{ width: "80%" }}>
            <div style={styles.contain}>
                <div style={styles.graphMain}>
                    <Bar data={chartData} options={options} />
                </div>
                {Array.from({ length: 6 }, (_, index) => (
                    <div key={index}>
                        {index > 1 ? <h2>Graph pour {QuestionAndAnswer.Formulaire[index + 2].Question}</h2> : <h2>Graph pour {QuestionAndAnswer.Formulaire[index].Question}</h2>}
                        <Bar
                            data={{
                                labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"],
                                datasets: [
                                    {
                                        label: "Moyen d'état de santé d’un contrôleur aérien en pourcentage",
                                        data: [
                                            monthlyAveragesByElement[0][index] ?? 0,
                                            monthlyAveragesByElement[1][index] ?? 0,
                                            monthlyAveragesByElement[2][index] ?? 0,
                                            monthlyAveragesByElement[3][index] ?? 0,
                                            monthlyAveragesByElement[4][index] ?? 0,
                                            monthlyAveragesByElement[5][index] ?? 0,
                                            monthlyAveragesByElement[6][index] ?? 0,
                                            monthlyAveragesByElement[7][index] ?? 0,
                                            monthlyAveragesByElement[8][index] ?? 0,
                                            monthlyAveragesByElement[9][index] ?? 0,
                                            monthlyAveragesByElement[10][index] ?? 0,
                                            monthlyAveragesByElement[11][index] ?? 0
                                        ],
                                        backgroundColor: "rgba(75,192,192,1)",
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                            options={{
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 100,
                                    },
                                },
                            }}

                        />
                    </div>
                ))}
            </div>
        </div>
    );
}    