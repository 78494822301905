import React, { useState } from "react";
import "./styles.css";
import GaugeChart from "react-gauge-chart";
import QuestionAndAnswer from '../../data/QuestionAndAnswer.json';
import axios from 'axios';
import feurouge from "../../../src/assets/feuRouge.png"
import feuvert from "../../../src/assets/feuVert.png"
import feujaune from "../../../src/assets/feuJaune.png"

const customSegmentStops = [0.15, 0.34, 0.46, 1];
const customLabels = ["0%", "34%", "46%", "100%"];
let boucle = 0;

export default function App() {
  const [gauge, setGauge] = useState(0);
  const [flag, setFlag] = useState(0);
  const [QuestionFlag, setQuestionFlag] = useState([]);
  const [Score, setScore] = useState(0);

  let Answer = 0;
  const listAnswer = [];
  let moyennbr = 0;
  let ScoreBrut = 0;
  localStorage.setItem("rating", true);

  const CheckFlag = (listAnswer) => {
    const newList = [];

    for (var i = 0; i < listAnswer.length; i++) {
      if (listAnswer[i] === 1 && i !== 8 && i !== listAnswer.length - 2) {
        if (i == 1 || i === 2 || (i == 6 && localStorage.getItem("selectedSpecialite") == "Pilote")) {
            newList.push(i)
        }
      }
    }
    if (newList.length > 0){
      setQuestionFlag(newList);
      return 1
    }

    /*for (var i = 0; i < listAnswer.length; i++) {   
        if (i == 0 || i == 3 || i == 4 || i == 5 || i == 6 || i == 8 || i == 9 ||i == 10 || i == 12 ){
          newList.push(i)
        }
    }*/

    /*if (newList.length >= 3){
      setQuestionFlag(newList);
      console.log("setokforkfoor")
      return 1
    }*/
    return 0
  }
  const returnhome = () => {
    window.location.href = 'http://gra.iftl-ev.fr'
  }

  const senddata = (listAnswer) => {
    console.log("listAnswer", listAnswer)
    var data = JSON.stringify({
      "name": "GRA",
      "location": localStorage.getItem("selectedSpecialite"),
      "stats": listAnswer
    });

    var config = {
      method: 'post',
      url: 'http://www.filgoods.iftl-ev.fr/ccer',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleGaugeIncrease = () => {
    var moyentype1 = 0;
    var moyentype2 = 0;
    var moyentype3 = 0;
    moyennbr = 0;
    let Responsable = localStorage.getItem("selectedResponsable")
    for (let i = 0; i < (QuestionAndAnswer.Formulaire.length); i++) {
      Answer = localStorage.getItem(QuestionAndAnswer.Formulaire[i].Question)
      if (Answer !== null) {
        if (Answer !== "99") {
          listAnswer.push(Answer !== 0 ? Answer / (QuestionAndAnswer.Formulaire[i].Answer.length - 1) : 0)
        }
        if (i >= 0 && i < 5) {
          if (i == 1) {
            moyentype1 += Answer == 0 ? 0  : Answer == 1 ? 5 : 10
          }
          else {
            moyentype1 += Answer == 0 ? 0 : Answer == 1 ? 3 : Answer == 2 ? 5 : 10
          }

        }
        else if (i >= 5 && i <= 8) {

            moyentype2 += Answer == 0 ? 0 : Answer == 1 ? 3 : Answer == 2 ? 5 : 10
        }
        else if (i >= 9 && i <= 13) {
          if (i == 11)
          {
            moyentype3 += Answer == 0 ? 0 : Answer == 1 ? 5 : 10
          }
          else {
            moyentype3 += Answer == 0 ? 0 : Answer == 1 ? 3 : Answer == 2 ? 5 : 10
          }
        }
        console.log("Score brut" ,moyentype1 + moyentype2 + moyentype3);
      }
      else {
        console.log("rentreeNULL", i)
      }
    }
    if (Responsable == "EPNER"){
      if (moyentype2 > 10){
        moyentype2 = 10
      }
    }
    ScoreBrut = (moyentype1 + moyentype2 + moyentype3 )

    moyentype1 = (moyentype1 / 50) * 100;
    moyentype2 = (moyentype2 / 30) * 100;
    moyentype3 = (moyentype3 / 50) * 100;
    
    localStorage.setItem("moyentype1", moyentype1);
    localStorage.setItem("moyentype2", moyentype2);
    localStorage.setItem("moyentype3", moyentype3);
    console.log("moyentype1", moyentype1);
    console.log("moyentype2", moyentype2);
    console.log("moyentype3", moyentype3);
    moyennbr = (moyentype1 + moyentype2 + moyentype3 ) / 3;
    console.log("moyennbr", moyennbr)
    senddata(listAnswer);

    if (CheckFlag(listAnswer) == 1) {
      setFlag(1);
    }
    else if (CheckFlag(listAnswer) == 2) {
      setFlag(2);
    }
    console.log("moyennbr", moyennbr)
    console.log("ScoreBrut", ScoreBrut)
    setScore(ScoreBrut);
    setGauge(((ScoreBrut / 60) * 100) / 100);
  };

  if (gauge === 0) {
    if (boucle <= 2){
      handleGaugeIncrease();
      boucle++;
    }
  }

  return (
    <div className="app">
      <div style={gaugeContainerStyle}>

        <GaugeChart
          style={chartStyle}
          textColor="#FFFAFA"
          needleColor="#FFFAFA"
          colors={["#00FF00", "#FFFF00", "#FF8000", "#FF0000"]}
          percent = {gauge >= 1 ? 1 : gauge == 0.75 || gauge == 0.50 || gauge > 0.25 ? gauge + 0.05 : gauge}
          animateDuration={4000}
          hideText={true}
          nrOfLevels={4}
        />
        <text>Votre score est de {Math.round(Score)}</text>
        <text style={styles.ratingmetertext}>{gauge >= 0.75 ? "Score a risque" :  gauge >= 0.50 ? "Zone de vigilance renforcée" : gauge > 0.25 ? "Zone de vigilance" : "Zone calme"}</text>
        {gauge > 0.75 || flag == 1 ? <img src={feurouge} alt="feurouge" style={styles.feuTricolore} /> : gauge > 0.25  || flag == 2 ? <img src={feujaune} alt="feujaune" style={styles.feuTricolore} /> : <img src={feuvert} alt="feuvert" style={styles.feuTricolore} />}
        <text style={styles.ratingmetertext}>
    {gauge >= 0.75 && flag === 1 ? (
      <>
        Consulter le chef PN pour score à risque et pour réponse {QuestionFlag.length > 1 ? "aux" : "à la"} question{QuestionFlag.length > 1 ? "s" : ""} :
        <ul>
          {QuestionFlag.map((questionIndex) => (
            <li key={questionIndex}>
              {QuestionAndAnswer.Formulaire[questionIndex].Question}
            </li>
          ))}
        </ul>
      </>
    ) : gauge >= 0.75 ? (
      "Consulter le chef PN"
    ) : flag === 1 ? (
      <>
        Consulter le chef PN pour l{QuestionFlag.length > 1 ? "es" : "a"} question{QuestionFlag.length > 1 ? "s" : ""} :
        <ul>
          {QuestionFlag.map((questionIndex) => (
            <li key={questionIndex}>
              {QuestionAndAnswer.Formulaire[questionIndex].Question}
            </li>
          ))}
        </ul>
      </>
    ) : null}
  </text>
      <button style={styles.buttonconfirm} onClick={returnhome}>Retour à l'accueil</button>
      </div>
    </div>
  );
}

const chartStyle = {
  height: 350
};

const gaugeContainerStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",

  height: 200,
  heightMax: 200,
  widthMax: 200,
  paddingTop: 50,
  paddingBottom: "40%"

};

const styles = {
  feuTricolore: {
    width: '25%', margin: "1%", objectFit: 'contain', objectPosition: 'center',
  },
  ratingmetertext: {
    color: 'white',
    fontSize: '100%',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  buttonconfirm: {
    backgroundColor: 'red',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
    padding: "2%"

    
  },
};
